<!-- 设置 - 系统基础信息管理 - 注册用户管理 - 列表 -->
<template>
  <div class="student" ref="student">
    <keep-alive>
      <list-template
          :total="total"
          :current-page="page"
          :table-data="tableData"
          :table-config="tableConfig"
          @onChangePage="changeCurrentPage"
      >
        <template slot="title">
          <el-form ref="form" :model="form" label-width="0">
            <div class="flex flex-align">
              <el-form-item>
                <el-date-picker
                    size="small"
                    v-model="form.date"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-button debounce size="small" type="primary" @click.prevent="onSearch(false)">查询</el-button>
              <el-button
                  size="small"
                  style="margin-left: 16rem"
                  debounce
                  @click.prevent="reset"
              >重置</el-button>
            </div>
          </el-form>
        </template>
      </list-template>
    </keep-alive>
  </div>
</template>

<script>
import ListTemplate from '@/components/pages/List'
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      // 表格搜索配置
      form: {
        date: [],
      },
      // 表格配置
      tableConfig: [
        {prop: 'mobile', label: '注册手机号'},
        {prop: 'name', label: '用户姓名'},
        {prop: 'student_name', label: '学生姓名'},
        {prop: 'relationship_name', label: '家长与学生关系',minWidth: '110rem'},
        {prop: 'source', label: '来源'},
        {prop: 'created_at', label: '注册时间'},
        {prop: 'ip_address', label: 'IP属地'},
        // {prop: 'gender', label: '性别',},
        {prop: 'wechat_number', label: '微信号'},
        {prop: 'wechat_openid', label: 'openID'},
      ],
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData: []
    }
  },
  computed: {
    ...mapState(['page'])
  },
  components: {
    ListTemplate
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    // 获取数据
    getData() {
      let params = {
        start_at: this.form.date[0],
        end_at: this.form.date[1]
      }
      this.$_register.get('/api/recruit/member', {params: {...params, page: this.page}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      })
    },
    // 搜索功能
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.getData()
    },

    //重置搜索框
    reset(){
      Object.keys(this.form).forEach((key)=>{
        this.form[key] = [];
      })
      this.$store.commit('setPage', 1)
      this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
@media only screen and (min-width: 1140rem){
  .el-form .el-input {
    width: auto !important;
  }
}
::v-deep .el-form-item{
  margin-right: 20rem !important;
  margin-bottom: 12rem !important;
}
::v-deep .el-button--small {
  padding: 9rem 15rem;
  font-size: 12rem;
  border-radius: 3rem;
}
::v-deep .el-button--small{
  height: 32rem;
  margin-bottom: 12rem;
}
</style>
